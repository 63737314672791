import api from '@/base/utils/request'

export const getWatermarkConfig = () => {
  return api({
    url: '/admin/admin/settings/getAdminWatermarkConfig',
    method: 'post'
  })
}

export const setWatermarkConfig = (data) => {
  return api({
    url: '/admin/admin/settings/setAdminWatermarkConfig',
    method: 'post',
    data
  })
}
