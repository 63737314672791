<template>
  <div class="container watermark-config flex" v-loading="loading">
    <el-form size="medium" label-position="right" label-width="85px" class="small-form">
      <div class="config-section-title">水印设置</div>
      <el-form-item label="水印功能：">
        <el-radio-group v-model="config.use_watermark">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <div class="info"><i class="el-icon-info"></i>开启后，设置的水印会在图片、视频、视频直播中出现</div>
      </el-form-item>
      <el-form-item label="支持类型：">
        <el-checkbox v-model="config.type.image" :true-label="1" :false-label="0">图片</el-checkbox>
        <el-checkbox v-model="config.type.video" :true-label="1" :false-label="0">视频</el-checkbox>
        <el-checkbox v-model="config.type.live" :true-label="1" :false-label="0">视频直播</el-checkbox>
      </el-form-item>
      <el-form-item label="水印图片：">
        <single-media-wall v-model="config.watermark_url" fit="contain"
         :width="212" :height="120" :border-radius="4" add-text="">
          <div slot="info" class="info"><i class="el-icon-info"></i>尺寸推荐，JPG、PNG格式、图片小于2M</div>
        </single-media-wall>
      </el-form-item>
      <el-form-item label="水印位置：">
        <!-- 水印位置，0：左上角，1：右上角，2：左下角，3：右下角 -->
        <el-radio-group v-model="config.position">
          <el-radio :label="0">左上角</el-radio>
          <el-radio :label="1">右上角</el-radio>
          <el-radio :label="2">左下角</el-radio>
          <el-radio :label="3">右下角</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="透明度：">
        <div class="flex">
          <el-slider v-model="config.transparency" style="flex: 1;"></el-slider>
          <span class="slider-value">{{config.transparency}} %</span>
        </div>
      </el-form-item>
      <el-form-item label="水印大小：">
        <div class="flex">
          <el-slider v-model="config.size" style="flex: 1;"></el-slider>
          <span class="slider-value">{{config.size}} %</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="preview-panel flex-col flex-center">
      <div class="placeholder" style="width: 530px; height: 300px;">
        <el-image v-if="config.watermark_url" :src="config.watermark_url" fit="contain"
                  style="position: absolute;"
                  :style="{
                      width: config.size + '%',
                      height: config.size + '%',
                      left: config.position % 2 === 0? 0 : 'unset',
                      right: config.position % 2 !== 0? 0 : 'unset',
                      top: config.position < 2? 0:'unset',
                      bottom: config.position >= 2? 0:'unset',
                      opacity: config.transparency / 100
                    }">
          <!-- 水印位置，0：左上角，1：右上角，2：左下角，3：右下角 -->
        </el-image>
      </div>
      <p>水印预览</p>
    </div>
    <fixed-action-bar v-if="!loading">
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import SingleMediaWall from "../../common/components/SingleMediaWall";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import {getWatermarkConfig, setWatermarkConfig} from "@/modules/system-admin/api/watermark-config";

export default {
  components: {
    FixedActionBar,
    SingleMediaWall,
    PopoverInputEdit,
  },
  data() {
    return {
      loading: true,
      config: {
        use_watermark: 1, // 是否启用水印
        "type": {  // 支持类型
          "image": 1,
          "video": 1,
          "live": 1
        },
        "watermark_url": "",
        "position": 0,// 水印位置，0：左上角，1：右上角，2：左下角，3：右下角
        "transparency": 100,// 透明度
        "size": 100  // 大小
      },
      showImageList: false,
      imageType: 0,
    };
  },
  watch: {
    config: {
      deep: true,
      handler(val) {

      },
    },
  },
  created() {
    getWatermarkConfig()
      .then((res) => {
        this.config = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
  methods: {
    saveConfig() {
      this.loading = true;
      let config = JSON.parse(JSON.stringify(this.config));
      setWatermarkConfig({
        config: config,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.watermark-config {
  flex-wrap: wrap;

  .small-form {
    max-width: 1440px;
    min-width: 500px;
  }

  .flex {
    width: 100%;
    max-width: 500px;

    .slider-value {
      width: 100px;
      flex-shrink: 0;
      white-space: nowrap;
      text-align: center;
    }
  }

  .preview-panel {
    margin-left: $space;
    .placeholder {
      background-color: $bg-color;
      position: relative;
    }

    p {
      margin: $space;
    }
  }
}
</style>
